import { isApeTheme } from "@/utils/theme";
import StratosphereBanner from "@components/Banner/Stratosphere";
import { useUserIsStratosphereMember } from "@state/user/hooks";
import useExchangeChartManager from "@state/user/hooks/useExchangeChartManager"; // 34kb
import { useMatchBreakpointsContext } from "@vapordex/uikit";
import { Ad } from "@vaporfi/features/src/Ad";
// import { Token } from '@vapordex/sdk'
// import { useAggregatedSwapInfo } from '@state/swap/hooks'
import { useEffect, useState } from "react";
// import ChartContainer from './Chartv2/components/ChartContainer'
import SwapViewForm from "./components/SwapViewForm";

function Swap({
	// inputCurrency,
	// outputCurrency,
	// initialChartData,
	// initialData,
	// coinGeckoData,
	isTermsEnabled,
	// independentField,
	typedValue,
	// recipient,
	// isChartEnabled,
}) {
	const { isDesktop, isMobile } = useMatchBreakpointsContext();
	const [isChartExpanded] = useState<boolean>(false);
	const [userChartPreference, setUserChartPreference] =
		useExchangeChartManager(isMobile);

	// swap warning state
	// const priceDataForChart = useAggregatedSwapInfo(
	//   independentField,
	//   '1000',
	//   inputCurrency,
	//   outputCurrency,
	//   recipient,
	// )

	const isStratosphereMember = useUserIsStratosphereMember();

	// const priceOfoutputInTermsOfInput = (
	//   +priceDataForChart?.parsedOutputAmount?.toExact() / 1000
	// )?.toString()

	// const getUsdPrice = (price: string) => {
	//   setUsdPriceOfOutput(price)
	// }

	useEffect(() => {
		setUserChartPreference(userChartPreference);
	}, [userChartPreference, setUserChartPreference]);

	const adProps = isApeTheme
		? {
				href: "#", // TODO: prod link
				src: "https://static.vapordex.io/a8abd656-dd97-4769-9500-cdc08111eb72.mp4",
				type: "video" as const,
			}
		: {
				href: "https://www.tekika.io",
				src: "https://static.vapordex.io/3409cb76-da1b-4450-99e2-b6baa5d30630.png",
				type: "image" as const,
			};

	return (
		<>
			<div className="flex w-full flex-col items-center">
				{isDesktop && !isStratosphereMember && (
					<StratosphereBanner style={{ width: "100%" }} />
				)}

				<div
					className={`relative w-full justify-center ${
						isDesktop ? "flex-row" : "flex-col-reverse"
					} ${!isDesktop && typedValue === "" ? "gap-14" : "gap-36"}`}
				>
					{/* {isChartEnabled ? (
            <ChartContainer
              inputCurrency={inputCurrency as Token}
              outputCurrency={outputCurrency as Token}
              price={priceOfoutputInTermsOfInput}
              getUsdPrice={getUsdPrice}
              initialChartData={initialChartData}
              initialData={initialData}
              initialMarketData={initialMarketData}
              coinGeckoData={coinGeckoData}
            />
          ) : null} */}

					<div className="flex flex-col items-center gap-5">
						<SwapViewForm
							isChartExpanded={isChartExpanded}
							isTermsEnabled={isTermsEnabled}
						/>
					</div>
				</div>
			</div>
			<Ad {...adProps} />
		</>
	);
}

export default Swap;
